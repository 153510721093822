.selected_page{
    font-weight: bold;
}

.users_content {
    margin-left: 200px;
}

input {outline:none;}

.search-block {
    display: inline;
}

.cancel-btn-block {
    display: inline-block;
    margin-left: 30px;
}

.cancel-btn{
    border: none;
    background-color: white;
    color: blue;
    cursor: pointer;
}
.search_string {
    margin-bottom: 20px;
    width: 400px;
    height: 40px;
    border-radius: 18px;
    border: solid 0px;
    box-shadow: 0 3px 12px 0px rgba(1, 64, 238, 0.2);
    padding-left: 16px;
    padding-right: 16px;
    font-size: 18px;
    color: rgba(84, 95, 97, 1);
}

.table {
    padding-right: 200px;
}

.action-block {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding-right: 200px;
}

@media (max-width: 1180px) {
    .action-block {
        display: block;
    }
  }

.sort {
    height: 40px;
}

.title-element{
    cursor: pointer; 
}

.user-ref{
    cursor: pointer;
    word-break: break-all;
}