.header {
    display:  flex;
    align-items: center;
    width: 100%;
    height: 8vh;
    background-color: #FFFFFF;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #E8E8E8;
}
  
.header__logo_block {
  display: flex;
  align-items: baseline;
}

.header__logo {
    margin-left: 200px;
  }

  .header__title {
    font-size: 30px;
    font-family: monospace;
  }

.nav {
  margin-left: 50px;
}
.item {
  margin-right: 20px;
}

.item a {
  color: rgb(90, 90, 90);
  font-size: 18px;
  line-height: 1.6;
  text-decoration: none;
  width: 170px;
}
.item a:hover {
  color: rgb(59, 59, 59);
}  
 
.item a.active {
  color: rgb(37, 8, 71);
}  

.btn-out {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 99px;
  height: 40px;
  margin: 20px 200px 20px auto;
  padding: 10px 25px;
  color: rgb(255, 255, 255);
  background: #5932EA;
  border-radius: 10px;
  border: none;
}

