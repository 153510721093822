.register-form__page {
    display: flex;
    width: 100vh;
    height: 87vh;
    flex-direction: column;
    justify-content: center;
}

.register-form__container {
    display: table;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: absolute;
    width: 600px;
    left: calc(50% - 400px/2);
    background: #FFFFFF;
    box-shadow: 0px 0px 25px rgba(35, 35, 96, 0.15);
    border-radius: 10px;
}

.register-form__page h2 {
    color: #232360;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
}

.register-form__form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.register-form__input {
    min-width: 100%;
    border: solid 1px rgba(229, 231, 235, 1);
    border-radius: 5px;
    outline: none;
    background-color: #F9FAFB;
    color: #000000;
    padding: 10px 15px;
    margin-bottom: 18px;
    font-size: 18px;
    resize: none;
}

.register-form__input:focus {
    border: 1px solid #546FFF;
    transition: border ease-in-out 0.3s;
}

.register-form__input::placeholder {
    color: #000000;
    opacity: 0.3;
}

.form-register_line {
    width: 224.5px;
    height: 0px;
    position: relative;
    top: 5px;
    background: #747676;
    opacity: 0.5;
    border: 1px solid #747676;
}

.form-register_or {
    width: 31px;
    height: 20px;
    margin: 0 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #747676;

}

.login-form__btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
    width: 99px;
    height: 40px;
    color: rgb(255, 255, 255);
    background: #5932EA;
    border-radius: 10px;
    border: none;
    margin: 0 20px 20px 20px;
}