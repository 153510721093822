.statistics-wrapper{
    margin-top: 40px;
    margin-left: 200px;
    padding-right: 200px;
}

#dropdown-custom{
    color: black;
    background-color: white;
    border: 1px solid grey;
    height: 26px;
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
}