.text-div {
    display: inline-block;
    margin-right: -10px;
    border: solid 1px black;
    width: 250px;
    height: 30px;
}

.text-input {
    border: none;
    width: 9px;
    height: 15px;
}

.priority-active {
    background-color: aqua;
}