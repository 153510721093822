.user-page-container {
    width: 100%;
    padding-left: 200px;
    padding-right: 200px;
}

.settings-page__background {
    display: block;
    width: auto;
    height: 282px;
    margin: 18px 18px 0px 18px;
    background-image: url(../../icons/jpg/bg_profile.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.settings-page__user-avatar {
    position: absolute;
    display: block;
    width: 137px;
    height: 137px;
    border-radius: 100%;
    background-color: #000;
    margin: 234px 0px 0px 50px; 
    background-image: url(../../icons/png/profile_avatar.jpg);
}

.settings-page__header {
    display: flex;
    margin: 20.5px 21px 0px 230px;
    align-items: center;
    justify-content: space-between;
}
  
.settings-page__title {
    font-style: 30px;
    font-weight: 700;
    line-height: 38px;
    color: #232360;
}

.settings-page__last-activity{
    margin-top: -40px;
    font-size: 16px;
    color: grey;
}

.user-page__data {
    margin-top: 50px;
}

.user-page__data-box {
    margin-top: 15px;
    width: 700px;
}

.user-page__data-box__name {
    display: inline-block;
    width: 100px;
    height: 30px;
}

.user-page__data-box__value {
    display: inline-block;
}

.save-btn {
    margin-top: 20px;
}